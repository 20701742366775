// .embed-video { height: 420px;}
.no-frameborder {
  border:none;
}


  iframe {
    border-radius: 14px!important;

    max-width: 100%;
    height: auto;
    min-height: 254px;
  }


.section-videos {
.video-item:hover {
  box-shadow: 0 10px 50px 0 rgba(0,0,0,0.20);
}
}


.single-videos  iframe { min-height: 540px; }
